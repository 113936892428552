<template>
  <div>
    <b-alert show variant="light" class="alert alert-elevate">
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-brand"></i>
      </div>
      <div class="alert-text">{{ $t("CLASS_MATERIALS_UPLOADED.GUIDE") }}</div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title">
          <template v-slot:body>
            <b-form class="kt-form" @submit.stop.prevent="onSubmit">
              <div
                role="alert"
                v-if="errors.length"
                v-bind:class="{ show: errors.length }"
                class="alert fade alert-danger"
              >
                <div class="alert-text">
                  <ul v-for="(error, i) in errors" :key="i">
                    <li>{{ error }}</li>
                  </ul>
                </div>
              </div>
              <b-overlay :show="uploading" variant="transparent" rounded="sm">
                <template v-slot:overlay>
                  <div class="text-center p-4 bg-info text-light rounded">
                    <i class="flaticon-upload" style="font-size: 3rem;"></i>
                    <div class="mb-3">
                      {{ $t("CLASS_MATERIALS_UPLOAD.PROCESSING") }}
                    </div>
                    <b-progress
                      :value="upload_percentage"
                      max="100"
                      variant="success"
                      height="3px"
                      class="mx-n4 rounded-0"
                    ></b-progress>
                  </div>
                </template>
                <b-row>
                  <b-col md="4">
                    <div role="group">
                      <label>{{ $t("GENERAL.FILE_NAME") }}:</label>
                      <b-form-input
                        id="file_name"
                        name="file_name"
                        type="text"
                        v-model="$v.form.file_name.$model"
                        :state="validateState('file_name')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-2-live-feedback">
                        {{
                          $t("VALIDATION.REQUIRED", {
                            name: $t("GENERAL.FILE_NAME")
                          })
                        }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div role="group">
                      <label>{{ $t("GENERAL.FILE_TYPE") }}:</label>
                      <b-form-select
                        id="file_type"
                        name="file_type"
                        v-model="$v.form.file_type.$model"
                        :state="validateState('file_type')"
                        :options="file_types"
                      ></b-form-select>
                      <b-form-invalid-feedback id="input-2-live-feedback">
                        {{
                          $t("VALIDATION.REQUIRED", {
                            name: $t("GENERAL.FILE_TYPE")
                          })
                        }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-col>
                  <b-col md="4" v-if="$v.form.file_type.$model !== 'YOUTUBE'">
                    <div role="group">
                      <label>{{ $t("GENERAL.SELECT_FILE") }}:</label>
                      <b-form-file
                        id="file"
                        name="file"
                        ref="file"
                        v-model="$v.form.file.$model"
                        accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                      application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,
                      application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/pdf,video/mp4,video/x-msvideo,video/x-ms-wmv,video/x-flv,video/x-matroska,video/quicktime,
                      video/3gpp,image/jpeg,image/png,image/gif"
                        :state="validateState('file')"
                        :placeholder="
                          $t('CLASS_MATERIALS_UPLOAD.SELECT_A_FILE')
                        "
                        :drop-placeholder="
                          $t('CLASS_MATERIALS_UPLOAD.DROP_FILE')
                        "
                      ></b-form-file>
                      <b-form-invalid-feedback id="input-2-live-feedback">
                        {{
                          $t("VALIDATION.REQUIRED", {
                            name: $t("GENERAL.SELECT_FILE")
                          }) +
                            ". " +
                            $t("VALIDATION.MEDIA_TYPE")
                        }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-col>
                  <b-col md="4" v-if="$v.form.file_type.$model === 'YOUTUBE'">
                    <div role="group">
                      <label>{{ $t("GENERAL.YOUTUBE_LINK") }}:</label>
                      <b-form-input
                        id="youtube_link"
                        name="youtube_link"
                        v-model="$v.form.youtube_link.$model"
                        :state="validateState('youtube_link')"
                        type="url"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-2-live-feedback">
                        {{
                          $t("VALIDATION.REQUIRED", {
                            name: $t("GENERAL.YOUTUBE_LINK")
                          })
                        }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <div role="group">
                      <label>{{ $t("GENERAL.DESCRIPTION") }}</label>
                      <editor
                        id="description"
                        name="description"
                        v-model="$v.form.description.$model"
                        apiKey="6i2ynvx6tmahwjbpz2k69fvox8ke77cq0ai0z4sasbh7jf0o"
                        :init="{
                          height: 400,
                          language: $i18n.locale !== 'en' ? 'fa' : 'en',
                          plugins: [
                            'fullscreen',
                            'link',
                            'paste',
                            'table',
                            'insertdatetime',
                            'wordcount',
                            'emoticons',
                            'directionality',
                            'visualblocks',
                            'visualchars',
                            'lists',
                            'advlist'
                          ],
                          toolbar_mode: 'sliding',
                          toolbar:
                            'undo redo ' +
                            '| bold italic underline strikethrough ' +
                            '| fontselect fontsizeselect formatselect ' +
                            '| alignleft aligncenter alignright alignjustify ' +
                            '| a11ycheck ltr rtl' +
                            '| outdent indent ' +
                            '|  numlist bullist checklist ' +
                            '| forecolor backcolor casechange permanentpen formatpainter removeformat ' +
                            '| charmap emoticons ' +
                            '| fullscreen  preview ' +
                            '| pageembed template link anchor '
                        }"
                      ></editor>
                      <b-form-invalid-feedback id="input-2-live-feedback">
                        {{
                          $t("VALIDATION.REQUIRED", {
                            name: $t("GENERAL.DESCRIPTION")
                          })
                        }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="kt-margin-t-5">
                    <b-button
                      type="submit"
                      id="kt_submit"
                      class="btn btn-primary btn-elevate kt-login__btn-primary"
                    >
                      {{ $t("CLASS_MATERIALS_UPLOAD.UPLOAD") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Editor from "@tinymce/tinymce-vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

import { validationMixin } from "vuelidate";
import { url, required, requiredIf } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      errors: [],
      title: this.$t("MENU.CLASS_MATERIALS_UPLOAD"),
      file_types: [
        { value: "FILE", text: "PDF/Word/Excel/PowerPoint/Video/Image" },
        { value: "YOUTUBE", text: "Youtube Video Link" }
      ],
      form: {
        file_name: null,
        file_type: null,
        file: null,
        youtube_link: null,
        description: null
      },
      uploading: false,
      upload_percentage: 0
    };
  },
  components: {
    KTPortlet,
    editor: Editor
  },
  validations: {
    form: {
      file_name: {
        required
      },
      file_type: {
        required
      },
      file: {
        required: requiredIf(function() {
          return this.$v.form.file_type.$model !== "YOUTUBE";
        })
      },
      youtube_link: {
        required: requiredIf(function() {
          return this.$v.form.file_type.$model === "YOUTUBE";
        }),
        url
      },
      description: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.errors = [];
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const file_name = this.$v.form.file_name.$model;
      const file_type = this.$v.form.file_type.$model;
      const description = this.$v.form.description.$model;
      let formData;
      let isFileUpload;
      let onUploading;
      if (file_type === "FILE") {
        isFileUpload = true;
        formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("file_name", file_name);
        formData.append("file_type", file_type);
        formData.append("file", this.$refs.file.value);
        formData.append("description", description);
        onUploading = function(progressEvent) {
          this.upload_percentage = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        }.bind(this);
      } else {
        const youtube_link = this.$v.form.youtube_link.$model;
        isFileUpload = false;
        formData = {
          id: this.$route.params.id,
          file_name: file_name,
          file_type: file_type,
          youtube_link: youtube_link,
          description: description
        };
      }
      this.uploading = true;
      ApiService.post("files/upload", formData, isFileUpload, onUploading)
        .then(() => {
          this.uploading = false;
          this.$router.push({ name: "lecturerUploadedMaterials" });
        })
        .catch(({ response }) => {
          this.uploading = false;
          this.errors = response.data.errors;
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.CLASS_MATERIALS_UPLOAD"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.post("lecturer/class-information", {
      id: this.$route.params.id
    })
      .then(({ data }) => {
        this.title +=
          " " +
          this.$t("CLASS_MATERIALS_UPLOAD.TITLE", {
            class: data.class_name,
            subject: data.subject,
            time: data.class_time,
            semester: data.semester
          });
      })
      .catch(({ response }) => {
        if (response && (response.status === 403 || response.status === 422)) {
          this.$router.push({ name: "lecturerAssignedClasses" });
        }
      });
  }
};
</script>
